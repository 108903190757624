import React from 'react';

const removeByElementId = (elementIds = []) => {
  elementIds.forEach(({ id }) => {
    document.getElementById(id).remove();
  });
};

const ActionContainer = props => {
  const { children, action, elementIds, attributes } = props;

  const triggerAction = () => {
    switch (action) {
      case 'remove-by-element-id':
        return removeByElementId(elementIds);
      default:
        return;
    }
  };

  return (
    <div onClick={triggerAction} {...attributes} aria-hidden="true">
      {children}
    </div>
  );
};

export default ActionContainer;
